<script setup>

import { nextTick, reactive, ref, toRaw, watch } from 'vue'
import { cloneDeep } from 'lodash'

const emit = defineEmits(['update', 'update:pristine'])

const formRef= ref()

const USER_CREATED_BY_OPTIONS = [
  { label: 'Migrated', value: 'V1_MIGRATION' },
  { label: 'Google SSO', value: 'V2_GOOGLE_SSO_LOGIN' },
  { label: 'Internal', value: 'V2_INTERNAL' },
  { label: 'Microsoft SSO', value: 'V2_MICROSOFT_SSO_LOGIN' },
  { label: 'OIDC SSO', value: 'V2_OIDC_SSO_LOGIN' },
  { label: 'Registration', value: 'V2_REGISTRATION' }
]

const USER_ROLES_OPTIONS = [
  { label: 'Owner', value: 'OWNER' },
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Manager', value: 'MANAGER' }
]

const BILLING_INTERVAL_OPTIONS = [
  { label: 'Any', value: null },
  { label: 'Monthly', value: 'month' },
  { label: 'Yearly', value: 'year' }
]

const TIER_GROUP_OPTIONS = [
  { label: 'Any', value: null },
  { label: 'Business', value: 'business' },
  { label: 'Education', value: 'education' }
]
const BILLING_INFO_STATUS_OPTIONS = [
  { label: 'Any', value: null },
  { label: 'Trialing', value: 'trialing' },
  { label: 'Demo', value: 'demo' },
  { label: 'Incomplete', value: 'incomplete' },
  { label: 'Paid', value: 'paid' },
  { label: 'Past Due', value: 'past_due' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Inactive', value: 'inactive' }
]

const DEFAULT_STATE = {
  createdAtGte: null,
  createdAtLte: null,
  emailVerified: null,
  lastLoginGte: null,
  lastLoginLte: null,
  onboardingFinished: null,
  userCreatedByIn: [],
  userRoleIn: [],
  workspaceBillingInfoBillingAmountGte: null,
  workspaceBillingInfoBillingAmountLte: null,
  workspaceBillingInfoBillingDevicesQuantityGte: null,
  workspaceBillingInfoBillingDevicesQuantityLte: null,
  workspaceBillingInfoBillingInterval: null,
  workspaceBillingInfoCurrentPeriodEndGte: null,
  workspaceBillingInfoCurrentPeriodEndLte: null,
  workspaceBillingInfoCurrentPeriodStartGte: null,
  workspaceBillingInfoCurrentPeriodStartLte: null,
  workspaceBillingInfoEnterprize: null,
  workspaceBillingInfoFreeDevicesQuantityGte: null,
  workspaceBillingInfoFreeDevicesQuantityLte: null,
  workspaceBillingInfoTierGroup: null,
  workspaceBillingInfoTierIn: [],
  workspaceDevicesCountGte: null,
  workspaceDevicesCountLte: null,
  workspaceFrozen: null,
  workspaceFrozenReasonIn: [],
  workspaceGroupsCountGte: null,
  workspaceGroupsCountLte: null,
  workspacePlaylistsCountGte: null,
  workspacePlaylistsCountLte: null,
  workspaceUsersCountGte: null,
  workspaceUsersCountLte: null,
  workspaceBillingInfoStatusIn: []
}

const TIERS = ['business-growth', 'business-enterprize', 'education-growth', 'education-enterprize', 'trial', 'business-trial', 'education-trial', 'education-starter', 'demo']

const TIERS_OPTIONS = TIERS.map((tier) => ({ label: tier, value: tier }))

const FROZEN_REASONS = [
  'FROZEN_TOO_MANY_DEVICES',
  'FROZEN_UNKNOWN',
  'MANUALLY_FROZEN',
  'SUBSCRIPTION_INACTIVE',
  'SUBSCRIPTION_TRIALING_ENDED',
  'SUBSCRIPTION_UNPAID'
]

const FROZEN_REASONS_OPTIONS = FROZEN_REASONS.map((reason) => ({ label: reason.toLowerCase().replace(/_/g, ' '), value: reason }))

const state = reactive(cloneDeep(DEFAULT_STATE))

const clearForm = async () => {
  Object.assign(state, cloneDeep(DEFAULT_STATE))
  await nextTick()
  emit('update:pristine', true)

}

defineExpose({
  reset: clearForm
})

// createdAtGte: DateTime
// createdAtLte: DateTime
// emailVerified: Boolean
// lastLoginGte: DateTime
// lastLoginLte: DateTime
// onboardingFinished: Boolean
// search: String
// userCreatedByIn: [UserCreatedByEnum!]
// userRoleIn: [WorkspaceRolesEnum!]
// workspaceBillingInfoBillingAmountGte: Int
// workspaceBillingInfoBillingAmountLte: Int
// workspaceBillingInfoBillingDevicesQuantityGte: Int
// workspaceBillingInfoBillingDevicesQuantityLte: Int
// workspaceBillingInfoBillingInterval: String
// workspaceBillingInfoCurrentPeriodEndGte: DateTime
// workspaceBillingInfoCurrentPeriodEndLte: DateTime
// workspaceBillingInfoCurrentPeriodStartGte: DateTime
// workspaceBillingInfoCurrentPeriodStartLte: DateTime
// workspaceBillingInfoEnterprize: Boolean
// workspaceBillingInfoFreeDevicesQuantityGte: Int
// workspaceBillingInfoFreeDevicesQuantityLte: Int
// workspaceBillingInfoTierGroup: String
// workspaceBillingInfoTierIn: [String!]
// workspaceDevicesCountGte: Int
// workspaceDevicesCountLte: Int
// workspaceFrozen: Boolean
// workspaceFrozenReasonIn: [WorkspaceFrozenReasonsEnum!]
// workspaceGroupsCountGte: Int
// workspaceGroupsCountLte: Int
// workspacePlaylistsCountGte: Int
// workspacePlaylistsCountLte: Int
// workspaceUsersCountGte: Int
// workspaceUsersCountLte: Int
// workspaceBillingInfoStatusIn: [WorkspaceBillingInfoStatusEnum!]

watch(()=> state, (newState) => {
  emit('update:pristine', false)
  emit('update', toRaw(newState))
}, {deep: true})


</script>

<template>
  <a-form
    ref="formRef"
    layout="vertical"
  >
    <a-row :gutter="[16,0]">
      <a-col :span="4">
        <a-form-item label="Created After">
          <a-date-picker
            v-model:value="state.createdAtGte"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Created Before">
          <a-date-picker
            v-model:value="state.createdAtLte"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Email Verified">
          <a-select
            v-model:value="state.emailVerified"
            style="width: 100%;"
          >
            <a-select-option :value="null">
              Any
            </a-select-option>
            <a-select-option :value="true">
              Yes
            </a-select-option>
            <a-select-option :value="false">
              No
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Last Login After">
          <a-date-picker
            v-model:value="state.lastLoginGte"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Last Login Before">
          <a-date-picker
            v-model:value="state.lastLoginLte"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="User Created By">
          <a-select
            v-model:value="state.userCreatedByIn"
            style="width: 100%;"
            mode="multiple"
            :options="USER_CREATED_BY_OPTIONS"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Onboarding Finished">
          <a-select
            v-model:value="state.onboardingFinished"
            style="width: 100%;"
          >
            <a-select-option :value="null">
              Any
            </a-select-option>
            <a-select-option :value="true">
              Yes
            </a-select-option>
            <a-select-option :value="false">
              No
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="User Role">
          <a-select
            v-model:value="state.userRoleIn"
            style="width: 100%;"
            mode="multiple"
            :options="USER_ROLES_OPTIONS"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Billing Amount Greater Than">
          <a-input-number
            v-model:value="state.workspaceBillingInfoBillingAmountGte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Billing Amount Less Than">
          <a-input-number
            v-model:value="state.workspaceBillingInfoBillingAmountLte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Billing Devices Qty Greater Than">
          <a-input-number
            v-model:value="state.workspaceBillingInfoBillingDevicesQuantityGte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Billing Devices Qty Less Than">
          <a-input-number
            v-model:value="state.workspaceBillingInfoBillingDevicesQuantityLte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Billing Interval">
          <a-select
            v-model:value="state.workspaceBillingInfoBillingInterval"
            style="width: 100%;"
            :options="BILLING_INTERVAL_OPTIONS"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Current Period End After">
          <a-date-picker
            v-model:value="state.workspaceBillingInfoCurrentPeriodEndGte"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Current Period End Before">
          <a-date-picker
            v-model:value="state.workspaceBillingInfoCurrentPeriodEndLte"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Current Period Start After">
          <a-date-picker
            v-model:value="state.workspaceBillingInfoCurrentPeriodStartGte"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Current Period Start Before">
          <a-date-picker
            v-model:value="state.workspaceBillingInfoCurrentPeriodStartLte"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Enterprize">
          <a-select
            v-model:value="state.workspaceBillingInfoEnterprize"
            style="width: 100%;"
          >
            <a-select-option :value="null">
              Any
            </a-select-option>
            <a-select-option :value="true">
              Yes
            </a-select-option>
            <a-select-option :value="false">
              No
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Free Devices Qty Greater Than">
          <a-input-number
            v-model:value="state.workspaceBillingInfoFreeDevicesQuantityGte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Free Devices Qty Less Than">
          <a-input-number
            v-model:value="state.workspaceBillingInfoFreeDevicesQuantityLte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Tier Group">
          <a-select
            v-model:value="state.workspaceBillingInfoTierGroup"
            style="width: 100%;"
            :options="TIER_GROUP_OPTIONS"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Tier">
          <a-select
            v-model:value="state.workspaceBillingInfoTierIn"
            style="width: 100%;"
            mode="multiple"
            :options="TIERS_OPTIONS"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Devices Count Greater Than">
          <a-input-number
            v-model:value="state.workspaceDevicesCountGte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Devices Count Less Than">
          <a-input-number
            v-model:value="state.workspaceDevicesCountLte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Frozen">
          <a-select
            v-model:value="state.workspaceFrozen"
            style="width: 100%;"
          >
            <a-select-option :value="null">
              Any
            </a-select-option>
            <a-select-option :value="true">
              Yes
            </a-select-option>
            <a-select-option :value="false">
              No
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Frozen Reason">
          <a-select
            v-model:value="state.workspaceFrozenReasonIn"
            style="width: 100%;"
            mode="multiple"
            :options="FROZEN_REASONS_OPTIONS"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Groups Count Greater Than">
          <a-input-number
            v-model:value="state.workspaceGroupsCountGte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Groups Count Less Than">
          <a-input-number
            v-model:value="state.workspaceGroupsCountLte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Playlists Count Greater Than">
          <a-input-number
            v-model:value="state.workspacePlaylistsCountGte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Playlists Count Less Than">
          <a-input-number
            v-model:value="state.workspacePlaylistsCountLte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Users Count Greater Than">
          <a-input-number
            v-model:value="state.workspaceUsersCountGte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Users Count Less Than">
          <a-input-number
            v-model:value="state.workspaceUsersCountLte"
            style="width: 100%;"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="Billing Info Status">
          <a-select
            v-model:value="state.workspaceBillingInfoStatusIn"
            style="width: 100%;"
            mode="multiple"
            :options="BILLING_INFO_STATUS_OPTIONS"
            placeholder="Any"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<style scoped>

</style>
