<script setup>

import { computed, nextTick, reactive, ref } from 'vue'
import { error, success } from '@/utils'
import { useMutation } from '@vue/apollo-composable'
import {
  CANCEL_WORKSPACE_SUBSCRIPTION,
  UPDATE_WORKSPACE_BY_ID,
  UPDATE_WORKSPACE_SUBSCRIPTION
} from '@/graphql/mutations'
import { WarningOutlined } from '@ant-design/icons-vue'

const { mutate: updateWorkspaceSubscriptionMutation, loading: updatingSubscription } = useMutation(UPDATE_WORKSPACE_SUBSCRIPTION)
const { mutate: updateWorkspaceMutation, loading: updatingWorkspace } = useMutation(UPDATE_WORKSPACE_BY_ID)
const { mutate: cancelWorkspaceSubscription, loading: cancelingWorkspaceSubscription } = useMutation(CANCEL_WORKSPACE_SUBSCRIPTION)


const loading = computed(() => updatingSubscription.value || updatingWorkspace.value || cancelingWorkspaceSubscription.value)

const props = defineProps({
  workspace: {
    type: Object,
    required: true
  },
  subscriptionDetails: {
    type: Object,
    required: true
  },
})

const emit = defineEmits(['update'])

const TIER_GROUPS = {
  BUSINESS: 'business',
  EDUCATION: 'education'
}

const TIER_GROUPS_OPTIONS = [
  {
    label: 'Business',
    value: TIER_GROUPS.BUSINESS
  },
  {
    label: 'Education',
    value: TIER_GROUPS.EDUCATION
  }
]

const BILLING_INTERVALS = {
  MONTHLY: 'month',
  YEARLY: 'year'
}

const BILLING_INTERVALS_OPTIONS = [
  {
    label: 'Monthly',
    value: BILLING_INTERVALS.MONTHLY
  },
  {
    label: 'Yearly',
    value: BILLING_INTERVALS.YEARLY
  }
]

const TRIAL_TIER_OPTIONS_MAP = {
  'education-trial': {
    title: 'Set Education Trial',
    tier: 'educationTrialItemsInfo'
  },
  'business-trial': {
    title: 'Set Business Trial',
    tier: 'businessTrialItemsInfo'
  }
}
const DEFAULT_TRIAL_DAYS = 14
const DEFAULT_TRIAL_DEVICES_QUANTITY = 50

const COLLECTION_METHODS = {
  CHARGE_AUTOMATICALLY: 'charge_automatically',
  SEND_INVOICE: 'send_invoice'
}

const COLLECTION_METHOD_OPTIONS = [
  {
    label: 'Automatically',
    value: COLLECTION_METHODS.CHARGE_AUTOMATICALLY
  },
  {
    label: 'Invoice',
    value: COLLECTION_METHODS.SEND_INVOICE
  }
]

const COLLECTION_METHOD_OPTIONS_MAP = COLLECTION_METHOD_OPTIONS.reduce((acc, { label, value }) => {
  acc[value] = label
  return acc
}, {})

const PAYMENT_METHOD_OPTIONS = [
  {
    label: 'Card',
    value: 'card'
  },
  {
    label: 'ACH Direct',
    value: 'ach_debit'
  },
  {
    label: 'Canadian Debit',
    value: 'acss_debit'
  },
  {
    label: 'Bank or Wire',
    value: 'us_bank_account'
  },
  {
    label: 'ACH credit',
    value: 'ach_credit_transfer'
  },
  {
    label: 'Check',
    value: 'paper_check'
  }
]

const showTrialSettingsModal = ref(false)
const showCustomPriceIdModal = ref(false)
const showUpdateSubscriptionModal = ref(false)

const customPriceInputRef = ref(null)
const trialDaysRef = ref(null)

const showNonPaidTierActions =computed(()=>{
  return ['inactive', 'unpaid', 'incomplete'].includes(props.workspace?.billingInfo?.status)
  || ['trial', 'business-trial', 'education-trial', 'education-starter', 'demo'].includes(props.workspace?.billingInfo?.tier)
})

const showPaidTierActions = computed(() => {
  return !['inactive', 'unpaid', 'incomplete'].includes(props.workspace?.billingInfo?.status)
  && ['business-growth', 'business-enterprize', 'education-growth', 'education-enterprize'].includes(props.workspace?.billingInfo?.tier)
})

const stripeCustomPriceId = ref('')

const trialSettingsState = reactive({
  trialDays: DEFAULT_TRIAL_DAYS,
  trialDevicesQuantity: DEFAULT_TRIAL_DEVICES_QUANTITY,
  trialTier: 'business-trial'
})

const subscriptionState = reactive({
  tierGroup: TIER_GROUPS.BUSINESS,
  billingDevicesQuantity: 1,
  growthBillingInterval: BILLING_INTERVALS.MONTHLY,
  stripeCustomPriceId: '',
  collectionMethod: COLLECTION_METHODS.SEND_INVOICE,
  daysUntilDue: 3,
  paymentMethodTypes: []
})

const setCreateSubscriptionState = () => {
  subscriptionState.tierGroup = TIER_GROUPS.BUSINESS
  subscriptionState.billingDevicesQuantity = 1
  subscriptionState.growthBillingInterval = BILLING_INTERVALS.MONTHLY
  subscriptionState.stripeCustomPriceId = ''
  subscriptionState.collectionMethod = COLLECTION_METHODS.SEND_INVOICE
  subscriptionState.daysUntilDue = 3
  subscriptionState.paymentMethodTypes = []
}

const setUpdateSubscriptionState = () => {
  subscriptionState.tierGroup = props.workspace?.billingInfo?.tierGroup || TIER_GROUPS.BUSINESS
  subscriptionState.billingDevicesQuantity = props.workspace?.billingInfo?.billingDevicesQuantity || 1
  subscriptionState.growthBillingInterval = props.workspace?.billingInfo?.stripeCustomPriceId
    ? null
    : (props.workspace?.billingInfo?.billingInterval || null)
  subscriptionState.stripeCustomPriceId = props.workspace?.billingInfo?.stripeCustomPriceId || ''
  subscriptionState.collectionMethod = props.subscriptionDetails?.billingSettings?.collectionMethod || COLLECTION_METHODS.SEND_INVOICE
  subscriptionState.daysUntilDue = props.subscriptionDetails?.billingSettings?.daysUntilDue || null
  subscriptionState.paymentMethodTypes = props.subscriptionDetails?.billingSettings?.paymentMethodTypes || []
}

const resetTrialSettings = (tier) => {
  trialSettingsState.trialDays = DEFAULT_TRIAL_DAYS
  trialSettingsState.trialDevicesQuantity = DEFAULT_TRIAL_DEVICES_QUANTITY
  trialSettingsState.trialTier = tier || 'business-trial'
}

const openTrialSettingsModal = async (tier) => {
  resetTrialSettings(tier)
  showTrialSettingsModal.value = true
  await nextTick()
  trialDaysRef.value?.focus()
}

const openCustomPriceIdModal = async () => {
  showCustomPriceIdModal.value = true
  stripeCustomPriceId.value = props.workspace?.billingInfo?.stripeCustomPriceId || ''
  await nextTick()
  customPriceInputRef.value?.focus()
}

const openCreateSubscriptionModal = () => {
  setCreateSubscriptionState()
  showUpdateSubscriptionModal.value = true
}

const openUpdateSubscriptionModal = () => {
  setUpdateSubscriptionState()
  showUpdateSubscriptionModal.value = true
}

const handleCancelTrialModal = () => {
  if (loading.value) return
  showTrialSettingsModal.value = false
  resetTrialSettings()
}

const handleCancelCustomPriceIdModal = () => {
  if (loading.value) return
  showCustomPriceIdModal.value = false
}

const handleCancelUpdateSubscriptionModal = () => {
  if (loading.value) return
  showUpdateSubscriptionModal.value = false
}

const updateSubscription = (input, onSuccess, onError) => {
  if (loading.value) return
  updateWorkspaceSubscriptionMutation({
    workspaceId: props.workspace.id,
    input
  }).then((data) => {
    emit('update')
    onSuccess && onSuccess(data)
  }).catch(e => {
    onError && onError(e)
  })
}

const updateWorkspace = (input, onSuccess, onError) => {
  if (loading.value) return
  updateWorkspaceMutation({
    id: props.workspace.id,
    input
  }).then((data) => {
    emit('update')
    onSuccess && onSuccess(data)
  }).catch(e => {
    onError && onError(e)
  })
}

const handleUpdateSubscription = () => {
  const input = {
    billingItemsInfo: {
      tierGroup: subscriptionState.tierGroup,
      billingDevicesQuantity: subscriptionState.billingDevicesQuantity,
      stripeCustomPriceId: subscriptionState.stripeCustomPriceId || null
    },
    billingSettings: {
      collectionMethod: subscriptionState.collectionMethod,
      daysUntilDue: subscriptionState.daysUntilDue || null,
      paymentMethodTypes: subscriptionState.paymentMethodTypes?.length ? subscriptionState.paymentMethodTypes : null
    }
  }

  if (!subscriptionState.stripeCustomPriceId) {
    input.billingItemsInfo.growthBillingInterval = subscriptionState.growthBillingInterval
  }

  updateSubscription(input, () => {
    success('Subscription updated')
    showUpdateSubscriptionModal.value = false
  }, (e) => {
    error(e.message)
  })
}

const handleSetTrial = () => {
  updateSubscription({
    [TRIAL_TIER_OPTIONS_MAP[trialSettingsState.trialTier].tier]: {
      trialDays: trialSettingsState.trialDays,
      trialDevicesQuantity: trialSettingsState.trialDevicesQuantity
    }
  }, () => {
    success('Trial added')
    showTrialSettingsModal.value = false
    resetTrialSettings()
  }, (e) => {
    error(e.message)
  })
}

const handleCancelWorkspaceSubscription = () => {
  cancelWorkspaceSubscription({ workspaceId: props.workspace.id }).then(() => {
    setTimeout(() => emit('update'), 1000)
    success('Subscription canceled')
  })
}

const moveToEDUStarterTier = () => {
  updateSubscription({
    educationStarterItemsInfo: {
      starterDevicesQuantity: 1
    }
  }, () => {
    success('Subscription updated')
  },
  (e) => {
    error(e.message)
  })
}

const setUpcomingPaymentsCustomPriceId = (priceId) => {
  priceId = priceId || null
  updateWorkspace({
    billingInfo: {
      stripeCustomPriceId: priceId
    }
  }, () => {
    showCustomPriceIdModal.value = false
    success('Custom Price updated')
  },
  (e) => {
    error(e.message)
  })
}


</script>

<template>
  <a-modal
    :open="showUpdateSubscriptionModal"
    :title="showNonPaidTierActions ? 'Create Subscription' : 'Update Subscription'"
    width="480px"
    :ok-button-props="{loading: loading}"
    :cancel-button-props="{disabled: loading}"
    @ok="handleUpdateSubscription"
    @cancel="handleCancelUpdateSubscriptionModal"
  >
    <a-form layout="vertical">
      <a-row :gutter="[16, 0]">
        <a-col :span="12">
          <a-form-item label="Tier Group">
            <a-select
              v-model:value="subscriptionState.tierGroup"
              :disabled="loading"
              :options="TIER_GROUPS_OPTIONS"
              style="width: 100%;"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Billing Devices Quantity">
            <a-input-number
              v-model:value="subscriptionState.billingDevicesQuantity"
              :min="1"
              :max="10000"
              style="width: 100%;"
              :disabled="loading"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item>
            <template #label>
              <a-typography-text :type="!!subscriptionState.stripeCustomPriceId ? 'danger': 'default'">
                Growth Billing Interval
              </a-typography-text>
            </template>
            <a-select
              v-model:value="subscriptionState.growthBillingInterval"
              :options="BILLING_INTERVALS_OPTIONS"
              :disabled="loading || !!subscriptionState.stripeCustomPriceId"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Stripe Custom Price Id">
            <a-input
              v-model:value="subscriptionState.stripeCustomPriceId"
              allow-clear
              :disabled="loading"
              style="width: 100%;"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Collection Method">
            <a-select
              v-model:value="subscriptionState.collectionMethod"
              :options="COLLECTION_METHOD_OPTIONS"
              style="width: 100%;"
              :disabled="loading"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Days Until Due">
            <a-input-number
              v-model:value="subscriptionState.daysUntilDue"
              :min="1"
              :max="30"
              style="width: 100%;"
              :disabled="loading"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Payment Method Types">
            <a-select
              v-model:value="subscriptionState.paymentMethodTypes"
              :options="PAYMENT_METHOD_OPTIONS"
              mode="multiple"
              style="width: 100%;"
              :disabled="loading"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <a-modal
    :open="showCustomPriceIdModal"
    title="Set Custom Price Id"
    width="480px"
    :ok-button-props="{loading: loading}"
    :cancel-button-props="{disabled: loading}"
    @ok="setUpcomingPaymentsCustomPriceId(stripeCustomPriceId)"
    @cancel="handleCancelCustomPriceIdModal"
  >
    <a-form layout="vertical">
      <a-form-item label="Custom Price Id">
        <a-input
          ref="customPriceInputRef"
          v-model:value="stripeCustomPriceId"
          allow-clear
          :disabled="loading"
          style="width: 100%;"
          @keydown.enter="setUpcomingPaymentsCustomPriceId(stripeCustomPriceId)"
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    width="480px"
    :open="showTrialSettingsModal"
    :title="TRIAL_TIER_OPTIONS_MAP[trialSettingsState.trialTier].title"
    :ok-button-props="{loading: loading}"
    :cancel-button-props="{disabled: loading}"
    @ok="handleSetTrial"
    @cancel="handleCancelTrialModal"
  >
    <a-form layout="vertical">
      <a-row :gutter="[16, 16]">
        <a-col :span="12">
          <a-form-item label="Trial Days">
            <a-input-number
              ref="trialDaysRef"
              v-model:value="trialSettingsState.trialDays"
              :min="0"
              :max="365"
              style="width: 100%;"
              :disabled="loading"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Trial Devices">
            <a-input-number
              v-model:value="trialSettingsState.trialDevicesQuantity"
              :min="1"
              :max="50"
              style="width: 100%;"
              :disabled="loading"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-typography-paragraph
      v-if="workspace?.billingInfo?.stripeCustomPriceId"
      type="warning"
    >
      <WarningOutlined />
      Custom price will be removed after update
    </a-typography-paragraph>
  </a-modal>
  <a-modal />
  <a-space direction="vertical">
    <a-space>
      <a-button
        :disabled="!showNonPaidTierActions"
        :loading="loading"
        @click="openTrialSettingsModal('education-trial')"
      >
        Make EDU Trial
      </a-button>
      <a-popconfirm
        title="Are you sure?"
        :disabled="!showNonPaidTierActions"
        @confirm="moveToEDUStarterTier"
      >
        <a-button
          :disabled="!showNonPaidTierActions"
          :loading="loading"
        >
          Make EDU Starter
        </a-button>
      </a-popconfirm>
      <a-button
        :disabled="!showNonPaidTierActions"
        :loading="loading"
        @click="openTrialSettingsModal('business-trial')"
      >
        Make Business Trial
      </a-button>
      <a-button
        :disabled="!showNonPaidTierActions"
        :loading="loading"
        @click="openCustomPriceIdModal()"
      >
        Set Custom Checkout Price
      </a-button>
      <a-button
        :loading="loading"
        @click="showNonPaidTierActions ? openCreateSubscriptionModal() : openUpdateSubscriptionModal()"
      >
        {{ showNonPaidTierActions ? 'Create Subscription' : 'Update Subscription' }}
      </a-button>
    </a-space>
    <a-space>
      <a-popconfirm
        title="Are you sure?"
        :disabled="!subscriptionDetails || subscriptionDetails?.status === 'canceled'"
        @confirm="handleCancelWorkspaceSubscription"
      >
        <a-button
          danger
          :loading="cancelingWorkspaceSubscription || loading"
          :disabled="!subscriptionDetails || subscriptionDetails?.status === 'canceled'"
        >
          Cancel Subscription
        </a-button>
      </a-popconfirm>
    </a-space>
  </a-space>
</template>

<style scoped>

</style>
